import { FC, useLayoutEffect } from 'react';
import FontFaceObserver from 'fontfaceobserver';
import { Switch, Redirect, Route, Router } from 'react-router-dom';
import ScrollToTop from 'components/ScrollToTopOnMount';
import ProductsListPage from 'pages/ProductsListPage';
import DownloadImages from 'pages/DownloadImages';
import { history } from 'routes/history';
import { ToastContainer } from 'react-toastify';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-toastify/dist/ReactToastify.css';

const App: FC = () => {
  const font = new FontFaceObserver('Raleway');
  const html = document.documentElement;

  useLayoutEffect(() => {
    html.classList.add('fonts-loading');

    font
      .load()
      .then(function () {
        html.classList.remove('fonts-loading');
        html.classList.add('fonts-loaded');
      })
      .catch(function () {
        html.classList.remove('fonts-loading');
        html.classList.add('fonts-failed');
      });
  });

  return (
    <div className="cto-pageWrapper">
      <Router history={history}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ScrollToTop />
        <Switch>
          <Route exact path="/product-list" component={ProductsListPage} />
          <Route path="/download-images" component={DownloadImages} />
          <Route exact path="/">
            <Redirect to="/product-list" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
