// Import here your languages
import en from './locales/en.json';
import it from './locales/it.json';
import de from './locales/de.json';
import es from './locales/es.json';
import fr from './locales/fr.json';

// Set here you favourite default language
export const defaultLanguage = 'it';

// Export here your language files import
export const languagesResources = {
  en,
  it,
  de,
  es,
  fr,
};
