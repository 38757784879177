import { FC, useCallback, useEffect, useState } from 'react';

import CTOContainer from 'components/CTOContainer';
import ProductsQuickAddCard from 'components/ProductsQuickAddCard';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import i18next from 'i18next';

import FileSaver from 'file-saver';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DownloadImages: FC = () => {
  const { t } = useTranslation();
  const [inputCodes, setInputCodes] = useState<string>('');
  const [loadingFetch, setLoadingFetch] = useState<boolean>(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const lang = queryParams.get('lang');

    if (!isNil(lang)) {
      i18next.changeLanguage(lang || '{}');
    }
  }, []);

  const handleSetInput = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setInputCodes(e.currentTarget.value);
  }, []);

  const handleDownloadImages = useCallback(() => {
    if (!isEmpty(inputCodes)) {
      const requestData = inputCodes.split(' ');

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          language_id: 1,
          products_ref: requestData,
        }),
      };
      setLoadingFetch(true);
      fetch(`${process.env.REACT_APP_BASE_API_URL}/carnival/products`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (!isEmpty(data)) {
            const items: any = [];
            // eslint-disable-next-line array-callback-return
            data?.map((item) => {
              items.push(item.code);
              setInputCodes('');
            });
            fetch(`${process.env.REACT_APP_BASE_API_URL}/carnival/images`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
              },
              body: JSON.stringify({
                language_id: 1,
                products_ref: items,
              }),
            })
              .then((response) => response.blob())
              .then((blob) => {
                const file = new Blob([blob], { type: 'octet/stream' });
                const fileName = `${t('DownloadImages:SelectedImages')}.zip`;
                FileSaver.saveAs(file, fileName);
                setLoadingFetch(false);
              })
              .catch(() => {
                setLoadingFetch(false);
                toast.error(`${t('Errors:DownloadImages')}`);
              });
          } else {
            setLoadingFetch(false);
            setInputCodes('');
          }
        })
        .catch(() => {
          setLoadingFetch(false);
          toast.error(`${t('Errors:CatalogAccess')}`);
        });
    }
  }, [inputCodes, t]);

  return (
    <CTOContainer>
      <div className="cto-page -downloadImages">
        <div className="cto-page__downloadComponentWrap">
          <ProductsQuickAddCard
            loading={loadingFetch}
            values={inputCodes}
            onChange={handleSetInput}
            search={handleDownloadImages}
            title={t('DownloadImages:QuickDownloadProductImages')}
            buttonText={t('DownloadImages:DownloadButton')}
          />
        </div>
      </div>
    </CTOContainer>
  );
};

export default DownloadImages;
