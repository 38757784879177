import { FC, ReactNode } from 'react';

interface ICTOContainer {
  children: ReactNode;
}

const CTOContainer: FC<ICTOContainer> = ({ children }) => {
  return (
    <div id="root" className="cto-contentWrapper">
      <div className="cto-mainPage">{children}</div>
    </div>
  );
};

export default CTOContainer;
