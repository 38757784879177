import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ICatalogProductCard {
  id: string;
  index: number;
  title: string;
  articleCode: string;
  image: string;
  length: number;
  deleteItem: (id: string) => void;
  moveProduct: (index: number, type: string) => void;
}

const CatalogProductCard: FC<ICatalogProductCard> = ({
  id,
  index,
  title,
  articleCode,
  image,
  length,
  deleteItem,
  moveProduct,
}) => {
  const { t } = useTranslation();

  const firstProduct = index === 0;
  const lastProduct = index === length;

  return (
    <div className={firstProduct ? 'cto-catalogProductCard cto-catalogProductFirstCard' : 'cto-catalogProductCard'}>
      <div className="cto-btnIcon -brand-01 -remove">
        <button className="cto-btnIcon__element " type="button" onClick={() => deleteItem(id)}>
          <i className="cto-icon-close"></i>
        </button>
      </div>
      <div className="cto-catalogProductCard__data">
        <div className="cto-catalogProductCard__thumbnailWrap">
          <img className="cto-catalogProductCard__thumbnail" src={image} width="90" height="108" alt="product row" />
        </div>
        <div className="cto-catalogProductCard__info">
          <p className="cto-catalogProductCard__name">{title}</p>
          <p className="cto-catalogProductCard__code">{`${t('CatalogProductCard:ArticleCode')} ${articleCode}`}</p>
        </div>
      </div>
      <div className="cto-catalogProductCard__actions">
        <div className="cto-btn -transparent -moveUp">
          <button
            className={firstProduct ? 'cto-btn__element cto-btn__elementDisabled' : 'cto-btn__element'}
            type="button"
            onClick={() => moveProduct(index, 'up')}
            disabled={firstProduct}
          >
            <i className="cto-icon-arrow-up"></i>

            <span className="cto-btn__text">{t('CatalogProductCard:UpButton')}</span>
          </button>
        </div>
        <div className="cto-btn -transparent -moveDown">
          <button
            className={lastProduct ? 'cto-btn__element cto-btn__elementDisabled' : 'cto-btn__element'}
            type="button"
            onClick={() => moveProduct(index, 'down')}
            disabled={lastProduct}
          >
            <i className="cto-icon-arrow-down"></i>

            <span className="cto-btn__text">{t('CatalogProductCard:DownButton')}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CatalogProductCard;
