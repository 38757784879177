import { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

interface ICatalogSidebar {
  pages: number | undefined;
  logoName: string | undefined;
  textValue: string | undefined;
  loading: boolean;
  onChangePrice: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTextUpdate: (e: React.FormEvent<HTMLInputElement>) => void;
  descriptionValue: string | undefined;
  onDescriptionUpdate: (e: React.FormEvent<HTMLInputElement>) => void;
  onLogoUpdate: (e: React.FormEvent<HTMLInputElement>) => void;
  onCreate: (e: React.FormEvent<HTMLInputElement>) => void;
  pdfOptionList: number[];
  onChangePdfType: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const CatalogSidebar: FC<ICatalogSidebar> = ({
  pages,
  logoName,
  textValue,
  loading,
  onChangePrice,
  onTextUpdate,
  descriptionValue,
  onDescriptionUpdate,
  onLogoUpdate,
  onCreate,
  pdfOptionList,
  onChangePdfType,
}) => {
  const { t } = useTranslation();
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    hiddenFileInput.current?.click();
  }, []);

  return (
    <form className="cto-catalogSidebar" action="#" method="get">
      <fieldset className="cto-catalogSidebar__section -customCatalog">
        <h4 className="cto-catalogSidebar__title">{t('CatalogSidebar:CatalogCustomize')}</h4>
        <div className="cto-formField">
          <label className="cto-formField__label">{t('CatalogSidebar:ChoosePageLayout')}</label>
          <div className="cto-select">
            <select className="cto-select__element" onChange={onChangePdfType}>
              {pdfOptionList.map((value) => (
                <option key={value} value={value}>
                  {t(`CatalogSidebar:PageLayoutType${value}`)}
                </option>
              ))}
            </select>
          </div>
          <p className="cto-formField__errorMessage">{t('CatalogSidebar:ErrorMessageExample')}</p>
        </div>
      </fieldset>
      <fieldset className="cto-catalogSidebar__section -productsPrice">
        <div className="cto-formField">
          <div className="cto-input -checkbox">
            <input className="cto-input__element" type="checkbox" onChange={onChangePrice} />
            <label className="cto-input__label">{t('CatalogSidebar:ShowProductsPrices')}</label>
          </div>
          <p className="cto-formField__errorMessage">{t('CatalogSidebar:ErrorMessageExample')}</p>
        </div>
      </fieldset>
      <fieldset className="cto-catalogSidebar__section -customHeader">
        <h4 className="cto-catalogSidebar__title">{t('CatalogSidebar:HeaderCustomize')}</h4>
        <div className="cto-formField">
          <label className="cto-formField__label">{t('CatalogSidebar:HeaderLogoCustomize')}</label>

          <div className="cto-input">
            <input
              onChange={onLogoUpdate}
              type="file"
              accept="image/png, image/jpeg"
              className="cto-input__fileInput"
              ref={hiddenFileInput}
            />
            <button className="cto-input -file -button" onClick={handleClick}>
              {t('CatalogSidebar:UploadLogo')}
            </button>
            <label className="cto-input__label">{logoName || t('CatalogSidebar:HeaderFileSelection')}</label>
          </div>
          <p className="cto-formField__errorMessage">{t('CatalogSidebar:ErrorMessageExample')}</p>
        </div>
        <div className="cto-formField">
          <label className="cto-formField__label">{t('CatalogSidebar:CatalogTextLabel')}</label>
          <div className="cto-input">
            <input
              className="cto-input__element"
              type="text"
              value={textValue}
              onChange={onTextUpdate}
              placeholder={t('CatalogSidebar:CatalogTextPlaceholder')}
            />
          </div>
          <p className="cto-formField__errorMessage">{t('CatalogSidebar:ErrorMessageExample')}</p>
        </div>
      </fieldset>
      <fieldset className="cto-catalogSidebar__section -customFooter">
        <h4 className="cto-catalogSidebar__title">{t('CatalogSidebar:FooterCustomize')}</h4>
        <div className="cto-formField">
          <label className="cto-formField__label">{t('CatalogSidebar:FooterDescriptionLabel')}</label>
          <div className="cto-input">
            <input
              className="cto-input__element"
              type="text"
              value={descriptionValue}
              onChange={onDescriptionUpdate}
              placeholder={t('CatalogSidebar:FooterInputPlaceholder')}
            />
          </div>
          <p className="cto-formField__errorMessage">{t('CatalogSidebar:ErrorMessageExample')}</p>
        </div>
      </fieldset>
      <fieldset className="cto-catalogSidebar__section -actions -borderless">
        <div className="cto-btn -createCatalog -brand-01">
          {loading ? (
            <div className="cto-btn__element">
              <Loader type="ThreeDots" color="#fff" width={40} height={10} />
            </div>
          ) : (
            <input
              className="cto-btn__element"
              type="submit"
              value={t('CatalogSidebar:CreateCatalog').toString()}
              readOnly
              onClick={onCreate}
            />
          )}
        </div>
      </fieldset>
      <p className="cto-catalogSidebar__pageTotal">
        {`${t('CatalogSidebar:Total')} ${pages} ${pages === 1 ? t('CatalogSidebar:Page') : t('CatalogSidebar:Pages')}`}
      </p>
    </form>
  );
};

export default CatalogSidebar;
