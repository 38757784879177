import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

interface IProductQuickAddCard {
  values: string;
  loading: boolean;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  search: (values: string) => void;
  title: string;
  buttonText: string;
}

const ProductQuickAddCard: FC<IProductQuickAddCard> = ({ values, loading, onChange, search, title, buttonText }) => {
  const { t } = useTranslation();

  return (
    <div className="cto-productsQuickAdd">
      <h4 className="cto-productsQuickAdd__title">{title}</h4>
      <div className="cto-productsQuickAdd__wrap">
        <div className="cto-formField">
          <label className="cto-formField__label">{t('ProductQuickAddCard:ProductsCodesSelection')}</label>
          <div className="cto-input">
            <input
              className="cto-input__element"
              type="text"
              id="example"
              name="example"
              value={values}
              onChange={onChange}
              placeholder={t('ProductQuickAddCard:ProductsCodes')}
            />
          </div>

          <p className="cto-formField__errorMessage">Error message example</p>
        </div>

        <div className="cto-btn -brand-01 -small">
          <button className="cto-btn__element" type="button" onClick={() => search(values)} disabled={loading}>
            {loading ? (
              <Loader type="ThreeDots" color="#fff" width={40} height={10} />
            ) : (
              <span className="cto-btn__text">{buttonText}</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductQuickAddCard;
