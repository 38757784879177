import { FC, useEffect, useState, useCallback, useMemo } from 'react';
import CTOContainer from 'components/CTOContainer';
import CatalogProductCard from 'components/CatalogProductCard';
import ProductsQuickAddCard from 'components/ProductsQuickAddCard';
import CatalogSidebar from 'components/CatalogSidebar';
import DownloadProductImageCard from 'components/DownloadProductImageCard';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import remove from 'lodash/remove';
import uniqBy from 'lodash/uniqBy';
import map from 'lodash/map';
import toNumber from 'lodash/toNumber';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import Loader from 'react-loader-spinner';

interface IFetchLoading {
  products: boolean;
  images: boolean;
  catalog: boolean;
  delete: boolean;
}
interface IProductDetail {
  code: string;
  description: string;
  id: string;
  image: string;
  name: string;
}
interface IProductsData {
  carnival_catalog_id: string;
  product: IProductDetail;
}
interface IProductsResponse {
  description: string;
  logo_url: string;
  name: string;
  rows: IProductsData[];
  text: string;
  user_id: string;
}

interface IProductCatalogFile {
  filename: string;
  filestream: string;
}

interface ISendProductsCatalog {
  description?: string;
  file?: IProductCatalogFile;
  name?: string;
  rows?: number[];
  text?: string;
  user_id?: string;
}

const ProductsListPage: FC = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState<IProductsResponse>();
  const [productsList, setProductsList] = useState<IProductDetail[]>([]);
  const [changed, setChanged] = useState<boolean>();
  const [inputCodes, setInputCodes] = useState<string>('');
  const [catalogRequest, setCatalogRequest] = useState<ISendProductsCatalog>();
  const [showPrices, setShowPrices] = useState<boolean>(false);
  const loadingDefault = {
    products: false,
    images: false,
    catalog: false,
    delete: false,
  };
  const [fetchLoading, setFetchLoading] = useState<IFetchLoading>(loadingDefault);
  const [selectedPdf, setSelectedPdf] = useState<string>('1');
  const uniqIdProductList = useMemo(() => uniqBy(productsList, 'id'), [productsList]);

  const handleGetCatalogPages = () => {
    const products = uniqIdProductList.length;
    return Math.ceil(products / toNumber(selectedPdf));
  };

  const handleSearchProduct = useCallback(() => {
    if (!isEmpty(inputCodes)) {
      const requestData = inputCodes.split(' ');
      const queryParams = new URLSearchParams(window.location.search);
      const language = queryParams.get('lang_id');

      const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify({
          language_id: language,
          products_ref: requestData,
        }),
      };
      setFetchLoading((prevState) => ({ ...prevState, products: true }));
      fetch(`${process.env.REACT_APP_BASE_API_URL}/carnival/products`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (!isEmpty(data)) {
            // eslint-disable-next-line array-callback-return
            data?.map((item) => {
              setProductsList((prevState) => [...prevState, item]);
              setInputCodes('');
            });
          } else {
            setInputCodes('');
          }
          setFetchLoading((prevState) => ({ ...prevState, products: false }));
        })
        .catch(() => {
          setFetchLoading((prevState) => ({ ...prevState, products: false }));
          toast.error('Non è stato possibile cercare i prodotti');
        });
    }
  }, [inputCodes]);

  const handleDownloadProductImages = () => {
    if (!isEmpty(products)) {
      const queryParams = new URLSearchParams(window.location.search);
      const user = queryParams.get('user_id');

      const requestOptions: RequestInit = {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(catalogRequest),
      };
      setFetchLoading((prevState) => ({ ...prevState, images: true }));
      fetch(`${process.env.REACT_APP_BASE_API_URL}/carnival/catalogs`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            fetch(`${process.env.REACT_APP_BASE_API_URL}/carnival/catalogs/images?user_id=${user}`)
              .then((response) => response.blob())
              .then((blob) => {
                const file = new Blob([blob], { type: 'octet/stream' });
                const fileName = `${t('DownloadImages:CatalogImages')}.zip`;
                FileSaver.saveAs(file, fileName);
              })
              .catch(() => {
                setFetchLoading((prevState) => ({ ...prevState, images: false }));
                toast.error(`${t('Errors:DownloadImages')}`);
              });
            setFetchLoading((prevState) => ({ ...prevState, images: false }));
          }
        })
        .catch(() => {
          setFetchLoading((prevState) => ({ ...prevState, images: false }));
          toast.error(`${t('Errors:UpdateCatalog')}`);
        });
    }
  };

  const handleDeleteProductsList = () => {
    setFetchLoading((prevState) => ({ ...prevState, delete: true }));

    const queryParams = new URLSearchParams(window.location.search);
    const user = queryParams.get('user_id');

    const requestOptions: RequestInit = {
      method: 'DELETE',
    };

    fetch(`${process.env.REACT_APP_BASE_API_URL}/carnival/catalog?user_id=${user}`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          toast.success(`${t('Success:DeleteCatalogList')}`);
          setProductsList([]);
        }
      })
      .catch(() => {
        toast.error(`${t('Errors:DeleteCatalogList')}`);
      });
    setFetchLoading((prevState) => ({ ...prevState, delete: false }));
  };

  const handleDeleteProduct = useCallback(
    (id) => {
      const queryParams = new URLSearchParams(window.location.search);
      const user = queryParams.get('user_id');

      const requestOptions: RequestInit = {
        method: 'DELETE',
      };

      fetch(
        `${process.env.REACT_APP_BASE_API_URL}/carnival/catalogRow?user_id=${user}&product_id=${id}`,
        requestOptions,
      )
        .then((response) => {
          if (response.status === 200) {
            const newElement = remove(uniqIdProductList, (item) => {
              return item.id !== id;
            });
            setProductsList(newElement);
          }
        })
        .catch(() => {
          toast.error(`${t('Errors:DeleteCatalogProduct')}`);
        });
    },
    [t, uniqIdProductList],
  );

  const handleSetInput = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setInputCodes(e.currentTarget.value);
  }, []);

  const handleChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowPrices(e.target.checked);
  };

  const handleChangePdf = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPdf(e.target.value);
  }, []);

  const handleTextUpdate = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const catalogText: string = e.currentTarget.value;
    setCatalogRequest((prevState) => ({ ...prevState, text: catalogText }));
  }, []);

  const handleDescriptionUpdate = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const descriptionText: string = e.currentTarget.value;
    setCatalogRequest((prevState) => ({ ...prevState, description: descriptionText }));
  }, []);

  const handleUpdateLogo = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    const target = e.target as HTMLInputElement;
    const file = (target.files as FileList)[0];
    if (reader !== undefined && file !== undefined) {
      reader.onloadend = () => {
        const image = reader.result?.toString().replace(/^data:image\/[a-z]+;base64,/, '');
        const fileData: IProductCatalogFile = {
          filename: file.name,
          filestream: image || '',
        };
        setCatalogRequest((prevState) => ({ ...prevState, file: fileData }));
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const handleCreateCatalog = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!isEmpty(catalogRequest)) {
      const requestOptions: RequestInit = {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(catalogRequest),
      };
      setFetchLoading((prevState) => ({ ...prevState, catalog: true }));
      fetch(`${process.env.REACT_APP_BASE_API_URL}/carnival/catalogs`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            const requestOptions: RequestInit = {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ price: showPrices, type: selectedPdf }),
            };

            fetch(
              `${process.env.REACT_APP_BASE_API_URL}/carnival/catalogs/pdf?user_id=${catalogRequest?.user_id}`,
              requestOptions,
            )
              .then((response) => response.json())
              .then((data) => {
                if (!isEmpty(data)) {
                  const binary = atob(data.filestream.replace(/\s/g, ''));
                  const len = binary.length;
                  const buffer = new ArrayBuffer(len);
                  const view = new Uint8Array(buffer);
                  for (var i = 0; i < len; i++) {
                    view[i] = binary.charCodeAt(i);
                  }

                  const file = new Blob([view], { type: 'application/pdf' });
                  const fileName = `${t('CatalogSidebar:CatalogCustom')}.pdf`;
                  FileSaver.saveAs(file, fileName);
                }
              })
              .then(() => {
                setFetchLoading((prevState) => ({ ...prevState, catalog: false }));
              })
              .catch(() => {
                setFetchLoading((prevState) => ({ ...prevState, catalog: false }));
                toast.error(`${t('Errors:DownloadPdf')}`);
              });
          } else {
            setFetchLoading((prevState) => ({ ...prevState, catalog: false }));
            toast.error(`${t('Errors:SaveCatalog')}`);
          }
        })
        .catch(() => {
          setFetchLoading((prevState) => ({ ...prevState, catalog: false }));
          toast.error(`${t('Errors:SaveCatalog')}`);
        });
    }
  };

  const handleMoveProduct = useCallback(
    (index, type) => {
      const newIndex = type === 'up' ? index - 1 : index + 1;
      uniqIdProductList.splice(newIndex, 0, uniqIdProductList.splice(index, 1)[0]);
      setChanged(!changed);

      const listIds = map(uniqIdProductList, 'id');

      setCatalogRequest((prevState) => ({ ...prevState, rows: listIds }));
    },
    [changed, uniqIdProductList],
  );

  const handleGoToHomepage = useCallback(() => {
    window.location.replace(`${process.env.REACT_APP_WEBSITE_PUBLIC_URL}`);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const lang = queryParams.get('lang');

    if (!isNil(lang)) {
      i18next.changeLanguage(lang || '{}');
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const user = queryParams.get('user_id');
    const language = queryParams.get('lang_id');
    setFetchLoading((prevState) => ({ ...prevState, products: true }));

    if (!isNil(user)) {
      fetch(`${process.env.REACT_APP_BASE_API_URL}/carnival/catalogs?user_id=${user}&language_id=${language}`)
        .then((response) => response.json())
        .then((data) => {
          setProducts(data);
          if (!isEmpty(data)) {
            // eslint-disable-next-line array-callback-return
            data?.rows.map((item) => {
              setProductsList((prevState) => [...prevState, item.product]);
            });
          }
        })
        .then(() => {
          setFetchLoading((prevState) => ({ ...prevState, products: false }));
        })
        .catch(() => {
          setFetchLoading((prevState) => ({ ...prevState, products: false }));
          toast.error(`${t('Errors:CatalogAccess')}`);
        });
    } else {
      setFetchLoading((prevState) => ({ ...prevState, products: false }));
      handleGoToHomepage();
    }
  }, [t, handleGoToHomepage]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const user = queryParams.get('user_id');
    const requestData: ISendProductsCatalog = {
      name: products?.name,
      user_id: user || products?.user_id,
      rows: map(uniqIdProductList, 'id'),
    };
    setCatalogRequest(requestData);
  }, [products, uniqIdProductList]);

  return (
    <CTOContainer>
      <div className="cto-page -customCatalog">
        <div className="cto-page__titleRow">
          <button className="cto-btn__element cto-btn__back" type="button" onClick={handleGoToHomepage}>
            <i className="cto-icon-chevron-left"></i>
            <span className="cto-btn__text">{t('Globals:GoBack')}</span>
          </button>
          <h1 className="cto-page__title">{t('ProductListPage:MyPersonalCatalog')}</h1>
        </div>
        <div className="cto-page__contentRow">
          <div className="cto-page__productsSection">
            <div className="cto-page__addProductsWrap">
              <ProductsQuickAddCard
                loading={fetchLoading.products}
                values={inputCodes}
                onChange={handleSetInput}
                search={handleSearchProduct}
                title={t('ProductListPage:ProductsQuickAdd')}
                buttonText={t('ProductListPage:AddButton')}
              />
            </div>
            <div className="cto-page__productsCardTitleWrap">
              {!isEmpty(uniqIdProductList) && (
                <p className="cto-page__productsCardTitleWrap">
                  {t('ProductListPage:SelectedArticles')}{' '}
                  <span className="cto-page__productsCardQtWrap">({uniqIdProductList.length})</span>
                </p>
              )}
            </div>
            {uniqIdProductList?.map((item, index) => {
              return (
                <div className="cto-page__productWrap" key={index}>
                  <CatalogProductCard
                    id={item.id}
                    index={index}
                    title={item.name}
                    articleCode={item.code}
                    image={item.image}
                    length={uniqIdProductList.length - 1}
                    deleteItem={handleDeleteProduct}
                    moveProduct={handleMoveProduct}
                  />
                </div>
              );
            })}
            <div className="cto-page__deleteProductsButtonWrap">
              <div className="cto-btn -transparent">
                {!isEmpty(uniqIdProductList) && (
                  <button
                    className="cto-btn__element"
                    type="button"
                    onClick={handleDeleteProductsList}
                    disabled={fetchLoading.delete}
                  >
                    {fetchLoading.delete ? (
                      <div className="cto-deleteLoader">
                        <Loader type="ThreeDots" color="#009640" width={30} height={10} />
                      </div>
                    ) : (
                      <i className="cto-icon-trash"></i>
                    )}
                    <span className="cto-btn__text">{t('ProductListPage:DeleteButton')}</span>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="cto-page__sidebarSection">
            <div className="cto-page__sidebarWrap">
              <CatalogSidebar
                loading={fetchLoading.catalog}
                logoName={catalogRequest?.file?.filename}
                textValue={catalogRequest?.text}
                onChangePdfType={handleChangePdf}
                pdfOptionList={[1, 4, 6, 8]}
                onChangePrice={handleChangePrice}
                onTextUpdate={handleTextUpdate}
                descriptionValue={catalogRequest?.description}
                onDescriptionUpdate={handleDescriptionUpdate}
                onLogoUpdate={handleUpdateLogo}
                pages={handleGetCatalogPages()}
                onCreate={handleCreateCatalog}
              />
            </div>
            <div className="cto-page__imagesDownloadWrap">
              <DownloadProductImageCard loading={fetchLoading.images} onDownload={handleDownloadProductImages} />
            </div>
          </div>
        </div>
      </div>
    </CTOContainer>
  );
};

export default ProductsListPage;
