import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

interface IDownloadProductImage {
  loading: boolean;
  onDownload: () => void;
}

const DownloadProductImageCard: FC<IDownloadProductImage> = ({ loading, onDownload }) => {
  const { t } = useTranslation();

  return (
    <div className="cto-downloadProdImagesCard">
      <h4 className="cto-downloadProdImagesCard__title">{t('DownloadProductImageCard:DownloadProductImages')}</h4>
      <h4 className="cto-downloadProdImagesCard__text">
        {t('DownloadProductImageCard:DownloadSelectedProductsImages')}
      </h4>
      <div className="cto-btn -createCatalog -brand-01">
        {loading ? (
          <div className="cto-btn__element">
            <Loader type="ThreeDots" color="#fff" width={40} height={10} />
          </div>
        ) : (
          <input
            disabled={loading}
            className="cto-btn__element"
            type="submit"
            value={t('DownloadProductImageCard:DownloadImages').toString()}
            readOnly
            onClick={onDownload}
          />
        )}
      </div>
    </div>
  );
};

export default DownloadProductImageCard;
